import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import { configs } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import AUKYCBankDetailPage from './AUKYCBankDetailPage';
import AUKYCBasicDetailPage from './AUKYCBasicDetailPage';
import AUKYCDematDetailPage from './AUKYCDematDetailPage';
import AUKYCReviewSubmitPage from './AUKYCReviewSubmitPage';
import AUKYCUploadDocumentPage from './AUKYCUploadDocumentPage';

export default function AUKYCMainPage() {

    // get kyc context to process kyc flow
    const { custKYCSwitchPage, currPage, kycDetails, getKYCSavedDetailContext, kycRequesterUserDetail } = useAURegister_KYCContext();
    const { setCurrModule, setcurrMod, setShowMobBS } = useLogin()

    document.title = `Customer KYC | Altius Investech`

    const history = useHistory()

    // get customer kyc saved details
    useEffect(() => {
        const controller = new AbortController();

        (async () => {
            try {
                if (kycRequesterUserDetail) {
                    setShowMobBS(false)
                    setCurrModule(configs.modules.kyc)
                    setcurrMod(configs.modules.kyc)
                    await getKYCSavedDetailContext();
                } else {
                    history.push('/clients')
                }
            } catch (error) {

            }
        })();
        return () => {
            controller.abort();
        };
    }, []);

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                {kycDetails ?
                    <div id='kycContainer' className="kyc-container">
                        <div id="kycMain" className="col-lg-6 d-md-block d-none kyc-main" >
                            <p className="text-custom-grey mt-4 mb-4 heading4 font-weight-bold">Let’s complete a quick KYC</p>
                            <div className="panel-card" onClick={() => custKYCSwitchPage(1)}>
                                <div className="number-curve">
                                    <span className={currPage === 1 ? "head-number-active" : currPage < 1 ? "head-number-next" : "head-number-prev"}>1</span>
                                </div>
                                <div className="number-heading">
                                    <span className={currPage === 1 ? "main-heading-active" : currPage < 1 ? "main-heading-next" : "main-heading-prev"}><a className={currPage === 1 ? "custom-link-active" : currPage < 1 ? "custom-link-next" : "custom-link-prev"}>Customer Basic Profile</a></span> <br />
                                    <span className="sub-heading">Name, Email, Mobile, PAN, Address</span>
                                </div>
                            </div>
                            <hr className="hr-kyc d-flex justify-content-start" />
                            <div className="panel-card" onClick={() => custKYCSwitchPage(2)}>
                                <div className="number-curve">
                                    <span className={currPage === 2 ? "head-number-active" : currPage < 2 ? "head-number-next" : "head-number-prev"}>2</span>
                                </div>
                                <div className="number-heading">
                                    <span className={currPage === 2 ? "main-heading-active" : currPage < 2 ? "main-heading-next" : "main-heading-prev"}><a className={currPage === 2 ? "custom-link-active" : currPage < 2 ? "custom-link-next" : "custom-link-prev"}>Demat Details</a></span> <br />
                                    <span className="sub-heading">DP Id, Client Id</span>
                                </div>
                            </div>
                            <hr className="hr-kyc d-flex justify-content-start" />
                            <div className="panel-card" onClick={() => custKYCSwitchPage(3)}>
                                <div className="number-curve">
                                    <span className={currPage === 3 ? "head-number-active" : currPage < 3 ? "head-number-next" : "head-number-prev"}>3</span>
                                </div>
                                <div className="number-heading">
                                    <span className={currPage === 3 ? "main-heading-active" : currPage < 3 ? "main-heading-next" : "main-heading-prev"}><a className={currPage === 3 ? "custom-link-active" : currPage < 3 ? "custom-link-next" : "custom-link-prev"}>Bank Details</a></span> <br />
                                    <span className="sub-heading">Bank Name, Bank Account no, IFSC code</span>
                                </div>
                            </div>
                            <hr className="hr-kyc d-flex justify-content-start" />
                            <div className="panel-card" onClick={() => custKYCSwitchPage(4)}>
                                <div className="number-curve">
                                    <span className={currPage === 4 ? "head-number-active" : currPage < 4 ? "head-number-next" : "head-number-prev"}>4</span>
                                </div>
                                <div className="number-heading">
                                    <span className={currPage === 4 ? "main-heading-active" : currPage < 4 ? "main-heading-next" : "main-heading-prev"}><a className={currPage === 4 ? "custom-link-active" : currPage < 4 ? "custom-link-next" : "custom-link-prev"}>Upload Documents</a></span> <br />
                                    <span className="sub-heading">Demat Account Details</span>
                                </div>
                            </div>
                            <hr className="hr-kyc d-flex justify-content-start" />
                            <div className="panel-card" onClick={() => custKYCSwitchPage(5)}>
                                <div className="number-curve">
                                    <span className={currPage === 5 ? "head-number-active" : currPage < 5 ? "head-number-next" : "head-number-prev"}><i className="fas fa-flag-checkered"></i></span>
                                </div>
                                <div className="number-heading">
                                    <span className={currPage === 5 ? "main-heading-active" : currPage < 5 ? "main-heading-next" : "main-heading-prev"}>Review &amp; Submit</span> <br />
                                    <span className="sub-heading">Start Investing</span>
                                </div>
                            </div>
                        </div>
                        {currPage === 1 ? <AUKYCBasicDetailPage />
                            : currPage === 2 ? <AUKYCDematDetailPage />
                                : currPage === 3 ? <AUKYCBankDetailPage />
                                    : currPage === 4 ? <AUKYCUploadDocumentPage />
                                        : currPage === 5 ? <AUKYCReviewSubmitPage />
                                            : null}
                    </div>
                    : null}
            </div>
            <BottomBarPage />
        </div>
    )
}