import React, { useEffect, useState } from 'react';
import { configs, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css'
import '../../../../assets/styles/Assets.css'
import '../../../../assets/styles/LeftBar.css'
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import ipo from '../../../../assets/images/ipo.png';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';

export default function AUUnlistedCompanyPage() {

    const { setCurrModule, setcurrMod, setShowDefaultMobBtn } = useLogin()
    const { assetList, setAssetList, dupAssetList, setDupAssetList, getAssetsListByClass, setAssetClass, getAssetDetailById, setAssetId } = useAUAssetMgmtContext()
    const { kycRequesterUserDetail } = useAURegister_KYCContext();

    const [activeTab, setActiveTab] = useState("0")
    const [expanded, setExpanded] = useState('false');
    const history = useHistory()

    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(async () => {
        const controller = new AbortController();

        document.title = `Unlisted Companies | Altius Investech`

        // if (!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("BOND")) {
        //     return history.push('/user/assets')
        // }

        if (kycRequesterUserDetail) {
            setCurrModule(configs.modules.otherAsset)
            setcurrMod(configs.modules.otherAsset)
            let temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
            let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            await getAssetsListByClass('company')

            temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
            temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            setTimeout(() => {
                if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                    document.getElementById("phWrapper").classList.toggle("d-none")
                }
            }, 500)
        } else {
            history.push('/clients')
        }
        return () => {
            controller.abort();
        };
    }, [])

    // filter companies
    function filterCompanies(e) {
        try {
            if (e.target.value && dupAssetList && dupAssetList.length > 0) {
                let tempList = []
                dupAssetList.forEach(element => {
                    if (element.company_name.toLowerCase().includes(e.target.value.toLowerCase())) {
                        tempList.push(element)
                    }
                });
                setAssetList(tempList)
            }
        } catch (error) {
            console.error(error);
        }
    }

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='assetContainer' className="assetMain" >
                    <div className=''>
                        <div id="assetOpp" className='mainDiv'>
                            <div className='row m-0 p-0 mb-3'>
                                <div className='col-12 m-0 p-0'>
                                    <Link to={`/user/assets`}>Alternate Assets</Link> &gt;&gt; <Link to={`/user/assets/company`}>Unlisted Companies</Link>
                                </div>
                            </div>
                            <div>
                                <div className='row align-items-center m-0 p-0 assetHeader'>
                                    <div className='col-md-8 col-12 m-0 p-0'>
                                        <p className="p-0 m-0 welcome-heading">Unlisted Companies</p>
                                        <p className="p-0 m-0 welcome-sub-heading">Invest before the IPO & earn superior returns</p>
                                        <div>
                                            <input type="search" className="form-control defaultFont" name="search" placeholder={`${assetList.length > 0 ? `Search eg: ${assetList[0].company_name}` : "Search company"}`} onKeyUp={filterCompanies} />
                                        </div>
                                        {/* <div className='row m-0 p-0 align-items-center'>
                                            <div className='col-12 m-0 p-0 refData d-flex'>
                                                <div className='refChange'>
                                                    <div className={`curPoint ${activeTab === "0" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("0")
                                                    }}>
                                                        <p className='m-0 p-0'>Currently Active</p>
                                                    </div>
                                                    <div className={`curPoint ${activeTab === "1" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("1")
                                                    }}>
                                                        <p className='m-0 p-0'>Recently Closed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='col-md-4 col-12 m-0 p-0 text-md-right text-center  d-md-block d-none'>
                                        <img className='assetHeaderImg' src={ipo} alt="ipo header image" />
                                    </div>
                                </div>
                                <div className="side-nav-company-table">
                                    {assetList && assetList.length > 0 ?
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Company Name</th>
                                                    <th className='text-right'>Buy Price</th>
                                                    <th className='text-right'>Sell Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assetList ? assetList.map((element, key) => {
                                                    return (
                                                        <tr className='subContent' key={`leftBarData` + key} onClick={() => {
                                                            // setCompanyId(element.company_id)
                                                            // setCurrModule(configs.modules.shares)
                                                            // leftBarDisplay(false)
                                                            // setShowOrderPayment(false)
                                                            // window.open(`/sharesDetail/${element.company_name}`, "_self")
                                                            setShowDefaultMobBtn(true)
                                                            history.push(`/user/assets/company/${element.company_name}`)
                                                        }}>
                                                            <td className="leftBar-table break-text text-custom-greyDark curPoint text-primary">{element.company_name}
                                                            </td>
                                                            {/* <td className="leftBar-table">
                                                                <button className="side-nav-button side-nav-buy-button">Buy</button>
                                                            </td> */}
                                                            <td className="leftBar-table text-right">
                                                                {formatCurrency(parseFloat(element.buy_price))}
                                                            </td>
                                                            <td className="leftBar-table text-right">
                                                                {formatCurrency(parseFloat(element.sell_price))}
                                                            </td>
                                                        </tr>

                                                    )
                                                }) : null
                                                }
                                            </tbody>
                                        </table>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PlaceHolderWrapper />
            </div>
            <BottomBarPage />
        </div>
    )
}