import React, { useEffect, useState } from 'react';
import { configs, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css'
import '../../../../assets/styles/Assets.css'
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import bond from '../../../../assets/images/bond.svg';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';

export default function AUBondsPage() {

    const { setCurrModule, setcurrMod } = useLogin()
    const { assetList, getAssetsListByClass, setAssetClass, getAssetDetailById, setAssetId } = useAUAssetMgmtContext()
    const { kycRequesterUserDetail } = useAURegister_KYCContext();

    const [activeTab, setActiveTab] = useState("0")
    const [expanded, setExpanded] = useState('false');
    const history = useHistory()

    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(async () => {
        const controller = new AbortController();

        document.title = `Bonds | Altius Investech`

        // if (!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("BOND")) {
        //     return history.push('/user/assets')
        // }
        if (kycRequesterUserDetail) {
            setCurrModule(configs.modules.otherAsset)
            setcurrMod(configs.modules.otherAsset)
            let temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
            let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            await getAssetsListByClass('bond')

            temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
            temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            setTimeout(() => {
                if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                    document.getElementById("phWrapper").classList.toggle("d-none")
                }
            }, 500)
        } else {
            history.push('/clients')
        }
        return () => {
            controller.abort();
        };
    }, [])

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='assetContainer' className="assetMain" >
                    <div className='assetBgImage'>
                        <div id="assetOpp" className='mainDiv'>
                            <div className='row m-0 p-0 mb-3'>
                                <div className='col-12 m-0 p-0'>
                                    <Link to={`/user/assets`}>Alternate Assets</Link> &gt;&gt; <Link to={`/user/assets/bond`}>Bonds</Link>
                                </div>
                            </div>
                            <div>
                                <div className='row align-items-center m-0 p-0 assetHeader'>
                                    <div className='col-md-8 col-12 m-0 p-0'>
                                        <p className="p-0 m-0 welcome-heading">Bonds</p>
                                        <p className="p-0 m-0 welcome-sub-heading">Earn a <b>fixed Income</b> on your investments. Bonds are less risky and also fetch around <b>9-11%</b> of returns.</p>
                                        <div className='row m-0 p-0 align-items-center'>
                                            <div className='col-12 m-0 p-0 refData d-flex'>
                                                <div className='refChange'>
                                                    <div className={`curPoint ${activeTab === "0" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("0")
                                                    }}>
                                                        <p className='m-0 p-0'>Currently Active</p>
                                                    </div>
                                                    <div className={`curPoint ${activeTab === "1" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("1")
                                                    }}>
                                                        <p className='m-0 p-0'>Recently Closed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12 m-0 p-0 text-md-right text-center  d-md-block d-none'>
                                        <img className='assetHeaderImg' src={bond} alt="bond header image" />
                                    </div>
                                </div>
                                <div className='row m-0 p-0'>
                                    {assetList && assetList.length > 0 ?
                                        assetList.map((asset, key) => {
                                            if (asset.disabletrading.toString() === activeTab) {
                                                return (
                                                    <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3' key={'bond' + key}>
                                                        <div className='assetCard'>
                                                            <div className='assetCardTopDiv'>
                                                                <div className='row m-0 p-0'>
                                                                    <div className='col-4 m-0 p-0 px-2'>
                                                                        <img className='assetOppImg' src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${asset.logo}`} alt={`${asset.nam} logo`} />
                                                                    </div>
                                                                    <div className='col-8 m-0 p-0'>
                                                                        <p className='m-0 p-0 fw-500 subheading2 text-left'>{asset.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className=''>
                                                                    <p className='m-0 p-0 text-left mt-3'>{asset.description}</p>
                                                                </div>
                                                            </div>
                                                            <div className='assetCardBottom'>
                                                                <div className='row m-0 p-0'>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{asset.rate_of_return}</p>
                                                                        <p className='m-0 p-0 subContent'>Pre-Tax IRR</p>
                                                                    </div>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{asset.maturity_period}</p>
                                                                        <p className='m-0 p-0 subContent'>Tenure</p>
                                                                    </div>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{formatCurrency(parseFloat(asset.minimum_investment ? asset.minimum_investment : 0))}</p>
                                                                        <p className='m-0 p-0 subContent'>Minimum investment</p>
                                                                    </div>
                                                                </div>
                                                                <div className='assetOppSector'>
                                                                    <div className='row m-0 p-0'>
                                                                        {asset.key_value.key_1 && asset.key_value.value_1 ?
                                                                            <div className='col-6 m-0 p-0 pr-1'>
                                                                                <p className='m-0 p-0'>{asset.key_value.key_1}</p>
                                                                                <p className='m-0 p-0 assetOppSectorValue'>{asset.key_value.value_1}</p>
                                                                            </div>
                                                                            : null}
                                                                        {asset.key_value.key_2 && asset.key_value.value_2 ?
                                                                            <div className='col-6 m-0 p-0 pl-1'>
                                                                                <p className='m-0 p-0'>{asset.key_value.key_2}</p>
                                                                                <p className='m-0 p-0 assetOppSectorValue'>{asset.key_value.value_2}</p>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                <div className="assetCardOppBtnDiv">
                                                                    <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setAssetClass("Bonds")
                                                                        setAssetId(asset.asset_id)
                                                                        getAssetDetailById(asset.asset_id, "bond")
                                                                        history.push(`/user/assets/assetDetail/bond/${asset.name}`)
                                                                    }}>View Details</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        : <div className='col-12 m-0 p-0'> <p className='font-weight-bold text-center'>No Bond Opportunities available</p></div>}
                                </div>
                            </div>
                            <div id="faq" className='assetHeader'>
                                <p className="p-0 m-0 welcome-heading">FAQs</p>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>What are bonds?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>A bond is a fixed-income instrument that represents a loan made by an investor to a borrower (typically corporate or governmental).</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>What is the minimum investment?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>With Altius you can start investing in Bonds for as low as Rs 10,000.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>Where do I see my bond after investment?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Once you have invested in any of the Bonds through Altius, the bond will be transferred to your DEMAT account and will be visible on the same.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>What is an unlisted bond?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Bonds that are not listed on the exchange are called Unlisted bonds. Even though these bonds are not listed, they can be bought and sold in the private market.<br />
                                            Though it is an Unlisted bond, it will still be visible in your DEMAT account after investment.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>How will I receive my TDS certificate?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>You will receive the TDS certificate via email at the end of each quarter in which the TDS is deducted, this can be used to file your ITR</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>How are returns calculated for my investment?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>The returns on the assets are XIRR (Extended Internal Rate of Return).<br />
                                            Returns are not calculated using the Simple Interest formula, XIRR is the metric used to calculate the returns on the future cash flows you receive on your investment.</Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>What is taxation on secured bonds?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>The taxation on the interest will be as per your tax slab, and a 10% TDS will be deducted as these are unlisted bonds.</Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>
                <PlaceHolderWrapper />
            </div>
            <BottomBarPage />
        </div>
    )
}