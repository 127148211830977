import React, { useEffect, useState } from 'react';
import { configs, formatCurrency, formatNumber } from '../../../../assets/Config';
import '../../../../assets/styles/KYC.css'
import '../../../../assets/styles/Assets.css'
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import startup from '../../../../assets/images/startup.svg';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';

export default function AUStartupPage() {

    const { setCurrModule, setcurrMod } = useLogin()

    const { assetList, getAssetsListByClass, getAssetDetailById, setAssetClass, setAssetId } = useAUAssetMgmtContext()
    const { kycRequesterUserDetail } = useAURegister_KYCContext();

    const [activeTab, setActiveTab] = useState("0")
    const [expanded, setExpanded] = useState('false');
    const history = useHistory()

    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(async () => {
        const controller = new AbortController();

        document.title = `Startup | Altius Investech`

        // if (!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("STARTUP")) {
        //     return history.push('/user/assets')
        // }
        if (kycRequesterUserDetail) {
            setCurrModule(configs.modules.otherAsset)
            setcurrMod(configs.modules.otherAsset)
            let temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
            let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            await getAssetsListByClass('startup')

            temp = document.getElementById("assetContainer") ? document.getElementById("assetContainer").classList.toggle("d-none") : null;
            temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            setTimeout(() => {
                if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                    document.getElementById("phWrapper").classList.toggle("d-none")
                }
            }, 500)
        } else {
            history.push('/clients')
        }
        return () => {
            controller.abort();
        };
    }, [])

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='assetContainer' className="assetMain" >
                    <div className='assetBgImage'>
                        <div id="assetOpp" className='mainDiv'>
                            <div className='row m-0 p-0 mb-3'>
                                <div className='col-12 m-0 p-0'>
                                    <Link to={`/user/assets`}>Alternate Assets</Link> &gt;&gt; <Link to={`/user/assets/startup`}>Start-ups</Link>
                                </div>
                            </div>
                            <div>
                                <div className='row align-items-center m-0 p-0 assetHeader'>
                                    <div className='col-md-8 col-12 m-0 p-0'>
                                        <p className="p-0 m-0 welcome-heading">Start-ups</p>
                                        <p className="p-0 m-0 welcome-sub-heading">Invest in <b>new-age startups</b> at a very early stage. This is your chance to become an angel investor and be a part of <b>upcoming Unicorns!</b></p>
                                        <div className='row m-0 p-0 align-items-center'>
                                            <div className='col-12 m-0 p-0 refData d-flex'>
                                                <div className='refChange'>
                                                    <div className={`curPoint ${activeTab === "0" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("0")
                                                    }}>
                                                        <p className='m-0 p-0'>Currently Active</p>
                                                    </div>
                                                    <div className={`curPoint ${activeTab === "1" ? "refActive" : "refInActive"}`} onClick={(e) => {
                                                        e.preventDefault()
                                                        setActiveTab("1")
                                                    }}>
                                                        <p className='m-0 p-0'>Recently Closed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 col-12 m-0 p-0 text-md-right text-center  d-md-block d-none'>
                                        <img className='assetHeaderImg' src={startup} alt="startup header image" />
                                    </div>
                                </div>
                                <div className='row m-0 p-0'>
                                    {assetList && assetList.length > 0 ?
                                        assetList.map((asset, key) => {
                                            if (asset.disabletrading.toString() === activeTab) {
                                                return (
                                                    <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3' key={'startup' + key}>
                                                        <div className='assetCard'>
                                                            <div className='assetCardTopDiv'>
                                                                <div className='row m-0 p-0'>
                                                                    <div className='col-4 m-0 p-0 px-2'>
                                                                        <img className='assetOppImg' src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${asset.logo}`} alt={`${asset.name} logo`} />
                                                                    </div>
                                                                    <div className='col-8 m-0 p-0'>
                                                                        <p className='m-0 p-0 fw-500 subheading2 text-left'>{asset.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className=''>
                                                                    <p className='m-0 p-0 text-left mt-3'>{asset.description}</p>
                                                                </div>
                                                            </div>
                                                            <div className='assetCardBottom'>
                                                                <div className='row m-0 p-0'>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{formatNumber(asset.percentage_booked)}%</p>
                                                                        <p className='m-0 p-0 subContent'>Percent Blocked</p>
                                                                    </div>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{asset.campaign_end_date}</p>
                                                                        <p className='m-0 p-0 subContent'>Campaign End</p>
                                                                    </div>
                                                                    <div className='col-4 m-0 px-1'>
                                                                        <p className='m-0 p-0 assetCardBottomNumber'>{formatCurrency(parseFloat(asset.minimum_investment ? asset.minimum_investment : 0))}</p>
                                                                        <p className='m-0 p-0 subContent'>Minimum investment</p>
                                                                    </div>
                                                                </div>
                                                                <div className='assetOppSector'>
                                                                    <div className='row m-0 p-0'>
                                                                        {asset.key_value.key_1 && asset.key_value.value_1 ?
                                                                            <div className='col-6 m-0 p-0 pr-1'>
                                                                                <p className='m-0 p-0'>{asset.key_value.key_1}</p>
                                                                                <p className='m-0 p-0 assetOppSectorValue'>{asset.key_value.value_1}</p>
                                                                            </div>
                                                                            : null}
                                                                        {asset.key_value.key_2 && asset.key_value.value_2 ?
                                                                            <div className='col-6 m-0 p-0 pl-1'>
                                                                                <p className='m-0 p-0'>{asset.key_value.key_2}</p>
                                                                                <p className='m-0 p-0 assetOppSectorValue'>{asset.key_value.value_2}</p>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                                <div className="assetCardOppBtnDiv">
                                                                    <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                                                        e.preventDefault()
                                                                        setAssetClass("STARTUP")
                                                                        setAssetId(asset.asset_id)
                                                                        getAssetDetailById(asset.asset_id, "startup")
                                                                        history.push(`/user/assets/assetDetail/startup/${asset.name}`)
                                                                    }}>View Details</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                        : <div className='col-12 m-0 p-0'> <p className='font-weight-bold text-center'>No Start-up Opportunities available</p></div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <PlaceHolderWrapper />
            </div>
            <BottomBarPage />
        </div>
    )
}