import React, { useEffect } from 'react';
import '../../../../assets/styles/KYC.css'
import '../../../../assets/styles/Assets.css'
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import assetMain from '../../../../assets/images/assetMain.svg';
import bond from '../../../../assets/images/bond.svg';
import mld from '../../../../assets/images/mld.svg';
import preipo from '../../../../assets/images/preipo.svg';
import ipo from '../../../../assets/images/ipo.png';
import GSec from '../../../../assets/images/GSec.svg';
import startup from '../../../../assets/images/startup.svg';
import { useHistory } from 'react-router-dom';
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import { configs } from '../../../../assets/Config';
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';

export default function AUAssetMainPage() {

    const { setCurrModule, setcurrMod } = useLogin()
    const { setAssetList } = useAUAssetMgmtContext()
    const { kycRequesterUserDetail } = useAURegister_KYCContext();

    const history = useHistory()

    useEffect(() => {
        const controller = new AbortController();
        document.title = `Alternate Assets | Altius Investech`

        // if (!configs.assetDisplay || configs.assetDisplay.length <= 0 || configs.assetDisplay.length === 1 && configs.assetDisplay[0].toLowerCase().includes("company")) {
        //     return history.push('/dashboard')
        // }
        if (kycRequesterUserDetail) {
            setCurrModule(configs.modules.otherAsset)
            setcurrMod(configs.modules.otherAsset)
        } else {
            history.push('/clients')
        }
        return () => {
            controller.abort();
        };
    }, [])

    // customer kyc main ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage {...{ display: true }} />
                <div id='assetContainer' className="assetMain" >
                    <div className='mainDiv'>
                        <div className='row align-items-center m-0 p-0 assetHeader'>
                            <div className='col-md-8 col-12 m-0 p-0'>
                                <p className="p-0 m-0 welcome-heading">Invest in Alternate Assets</p>
                                <p className="p-0 m-0 welcome-sub-heading">Diversify your investments by adding these alternate assets to your portfolio.</p>
                            </div>
                            <div className='col-md-4 col-12 m-0 p-0 text-md-right text-center d-md-block d-none'>
                                <img className='assetHeaderImg' src={assetMain} alt="Alternate asset header image" />
                            </div>
                        </div>
                        <div className='row m-0 p-0 justify-content-center'>
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("BOND") ? null : */}
                            <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={ipo} alt="ipo header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 subheading2 fw-500'>Pre IPO/Unlisted Equities</p>
                                                <p className='m-0 p-0'>Invest before the IPO & earn superior returns</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>%</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Average Yield</p>
                                                        <p className='m-0 p-0 subContent'>Up to 11% p.a.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>10,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/company')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div>
                            {/* } */}
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("BOND") ? null : */}
                            <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={bond} alt="bond header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 subheading2 fw-500'>Bonds</p>
                                                <p className='m-0 p-0'>Earn a fixed income on your investments</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>%</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Average Yield</p>
                                                        <p className='m-0 p-0 subContent'>Up to 11% p.a.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>10,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/bond')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div>
                            {/* } */}
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("MLD") ? null : */}

                            <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={mld} alt="mld header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 subheading2 fw-500'>MLDs</p>
                                                <p className='m-0 p-0'>Returns are linked to the market and your capital is always secured</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>%</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Average Return</p>
                                                        <p className='m-0 p-0 subContent'>linked to the underlying asset</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>10,00,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/mld')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div>
                            {/* } */}
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("GOVERNMENT_SECURITIES") ? null : */}

                            <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={GSec} alt="government securities header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 subheading2 fw-500'>Government Securities</p>
                                                <p className='m-0 p-0'>Earn Secured and better returns than your bank FDs </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>%</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Average Yield</p>
                                                        <p className='m-0 p-0 subContent'>Up to 7% p.a.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>10,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/gsec')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div>
                            {/* } */}
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("PRE_IPO_BUCKET") ? null : */}

                            <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={preipo} alt="Pre-IPO Bucket header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 subheading2 fw-500'>Pre-IPO Basket</p>
                                                <p className='m-0 p-0'>Invest in a basket of handpicked Pre IPO opportunities </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    {/* <div className='col-2 m-0 p-0 heading4'>%</div> */}
                                                    <div className='col-12 m-0 p-0'>
                                                        {/* <p className='m-0 subContentp-0 '>Average Yield</p> */}
                                                        <p className='m-0 p-0 subContent'>Invest in curated portflio of Pre-IPO companies</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>40,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/preipobucket')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div>
                            {/* } */}
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("MIX_BUCKET") ? null :

                                <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                    <div className='assetCard'>
                                        <div className='assetCardTopDiv'>
                                            <div className='row m-0 p-0'>
                                                <div className='col-md-12 col-4 m-0 p-0'>
                                                    <img className="assetCardImg" src={mld} alt="Mix Bucket header image" />
                                                </div>
                                                <div className='col-md-12 col-8 m-0 p-0'>
                                                    <p className='m-0 p-0 subheading2 fw-500'>Mix Basket</p>
                                                    <p className='m-0 p-0'>Invest in new-age startups at a very early stage.  </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='assetCardBottom'>
                                            <div className='row m-0 p-0'>
                                                <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                    <div className='row m-0 p-0'>
                                                        <div className='col-12 m-0 p-0'>
                                                            <p className='m-0 p-0 subContent'>Invest early in future Unicorns!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                    <div className='row m-0 p-0'>
                                                        <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                        <div className='col-10 m-0 p-0'>
                                                            <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                            <p className='m-0 p-0 subContent'>1,00,000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="assetCardOppBtnDiv">
                                            <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                                e.preventDefault()
                                                setAssetList("")
                                                history.push('/user/assets/mixbucket')
                                            }}>View Opportunities</div>
                                        </div>

                                    </div>
                                </div>} */}
                            {/* {!configs.assetDisplay || configs.assetDisplay.length <= 0 || !configs.assetDisplay.includes("STARTUP") ? null : */}

                            <div className='col-lg-4 col-md-6 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={startup} alt="Startup header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 subheading2 fw-500'>Startup Investing</p>
                                                <p className='m-0 p-0'>Invest in new-age startups at a very early stage.  </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    {/* <div className='col-2 m-0 p-0 heading4'>%</div> */}
                                                    <div className='col-12 m-0 p-0'>
                                                        {/* <p className='m-0 subContentp-0 '>Average Yield</p> */}
                                                        <p className='m-0 p-0 subContent'>Invest early in future Unicorns!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>1,00,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/startup')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div>
                            {/* } */}
                            {/* <div className='col-md-4 col-12 m-0 p-0 py-3'>
                                <div className='assetCard'>
                                    <div className='assetCardTopDiv'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-md-12 col-4 m-0 p-0'>
                                                <img className="assetCardImg" src={realEstate} alt="bond header image" />
                                            </div>
                                            <div className='col-md-12 col-8 m-0 p-0'>
                                                <p className='m-0 p-0 '>Fractional Real Estate</p>
                                                <p className='m-0 p-0'>Own a fraction of a high potential commercial real estate</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='assetCardBottom'>
                                        <div className='row m-0 p-0'>
                                            <div className='col-6 m-0 p-0 assetCardBottomLeft'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>%</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent'>Average Yield</p>
                                                        <p className='m-0 p-0 subContent'>Up to 8.6% p.a.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 m-0 p-0 assetCardBottomRight'>
                                                <div className='row m-0 p-0'>
                                                    <div className='col-2 m-0 p-0 heading4'>&#8377;</div>
                                                    <div className='col-10 m-0 p-0'>
                                                        <p className='m-0 p-0 subContent fw-500'>Min. Investment</p>
                                                        <p className='m-0 p-0 subContent'>25,00,000</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assetCardOppBtnDiv">
                                        <div className="assetCardOppBtn curPoint" onClick={(e) => {
                                            e.preventDefault()
                                            setAssetList("")
                                            history.push('/user/assets/realEstate')
                                        }}>View Opportunities</div>
                                    </div>

                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <BottomBarPage />
        </div>
    )
}