import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Table, TextField, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import MaterialTable from 'material-table';
import Chart from 'react-google-charts';
import { configs, formatCurrency, formatNumber, formatPressDate, testImage } from '../../../../assets/Config';
import '../../../../assets/styles/Shares.css'
import { useLogin } from '../../../../services/contexts/CustomerLoginContext';
import LeftBarPage from '../../../includes/dashboard/LeftBarPage';
import TopBarPage from '../../../includes/dashboard/TopBarPage';
import BottomBarPage from '../../../includes/dashboard/BottomBarPage';
import PlaceHolderWrapper from '../../../includes/PlaceHolderWrapper';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import { useAUAssetMgmtContext } from '../../../../services/contexts/assistedUser/assets/AUAssetMgmtContext';
import { useHistory, useParams } from 'react-router-dom';
import cdsl from '../../../../assets/images/cdsl.png';
import nsdl from '../../../../assets/images/nsdl.jpg';
import ToastPage from '../../../includes/ToastPage';
import AUVerifyOrderPopupPage from './AUVerifyOrderPopupPage';
import { useAURegister_KYCContext } from '../../../../services/contexts/assistedUser/registration_kyc/AURegister_KYCContext';
import { Autocomplete } from '@material-ui/lab';

if (typeof Highcharts === "object") {
    HighchartsSunburst(Highcharts);
}

export default function AUUnlistedCompanyDetailPage() {

    const { setCurrModule, proxyEmail, setShowMobBS, showMobBS, setShowSuccess } = useLogin()
    const { assetList, assetDetail, getAssetDetailById, setAssetId, assetId, getAssetsListByClass, getAssetPriceChartDataById, priceChartData, setPriceChartData, getAssetSlabDetailById, assetSlabsDetail, setOrderType, shareSub, setShareSub, createAssetBuyOrder, createAssetSellOrder, verifyOrderPopup, setVerifyOrderPopup, assetOrderDetailById, dupAssetList } = useAUAssetMgmtContext()
    const { kycRequesterUserDetail } = useAURegister_KYCContext();

    const [activeDetailTab, setActiveDetailTab] = useState("")
    const [chartData, setChartData] = useState("")
    const [graphDataSelector, setGraphDataSelector] = useState("MAX")
    const [quantity, setQuantity] = useState("")
    const [rate, setRate] = useState("")
    const [cost, setCost] = useState("")
    const [slab, setSlab] = useState("")
    const [error, setError] = useState("")

    const [sellQuant, setSellQuant] = useState("")
    const [sellRate, setSellRate] = useState("")
    const [sellType, setSellType] = useState(configs.shareSub.buy)
    const [sellRemark, setSellRemark] = useState("")
    const [sellError, setSellError] = useState("")

    const history = useHistory();

    let { companyName } = useParams();

    // get all assets
    useEffect(() => {
        (async () => {
            if (kycRequesterUserDetail) {
                await getAssetsListByClass('company')
            } else {
                history.push('/clients')
            }
        })()
    }, [])

    // get asset list and filter asset
    useEffect(() => {
        const controller = new AbortController();

        // setCurrModule(configs.modules.shares);
        // setShowOrderPayment(false)
        (async () => {
            // if (companyName && !assetId) {
            let tempAsset = '';
            for (let i = 0; i < assetList.length; i++) {
                if (assetList[i].company_name === companyName) {
                    tempAsset = assetList[i].company_id;
                    break;
                }
            }

            if (companyName) {
                document.title = `${companyName} | Altius Investech`
            }

            let temp = document.getElementById("shareContainer") ? document.getElementById("shareContainer").classList.toggle("d-none") : null;
            let temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;


            await getAssetDetailById(tempAsset, 'company');
            await getAssetSlabDetailById(tempAsset)
            await getAssetPriceChartDataById(tempAsset, "MAX")
            setActiveDetailTab(configs.sharesHeaderList[0])
            setAssetId(tempAsset);

            temp = document.getElementById("shareContainer") ? document.getElementById("shareContainer").classList.toggle("d-none") : null;
            temp1 = document.getElementById("phWrapper") ? document.getElementById("phWrapper").classList.toggle("d-none") : null;

            setTimeout(() => {
                if (document.getElementById("phWrapper") && document.getElementById("phWrapper").classList && !document.getElementById("phWrapper").classList.value.includes("d-none")) {
                    document.getElementById("phWrapper").classList.toggle("d-none")
                }
            }, 500)


            // }
        })()
        // return () => {
        //     controller.abort();
        // };
    }, [companyName])

    // get asset detail by asset id
    // useEffect(() => {
    //     const controller = new AbortController();
    //     (async () => {
    //         if (assetId) {


    //         }
    //     })()
    //     // return () => {
    //     //     controller.abort();
    //     // };

    // }, [assetId])

    // donut chart data
    function generateShareHoldingData(type, data) {
        try {
            let result = []
            result.push({
                id: "1",
                name: "Promoter",
                value: assetDetail.shareholding.shareholding_summary["Promoter %"],
                noDrill: true,
                color: "#a1bce2"
            }, {
                id: "2",
                name: "Public",
                value: assetDetail.shareholding.shareholding_summary["Public %"],
                noDrill: true,
                color: "#ea7070"
            })

            assetDetail.shareholding.shareholding_public.map((element, key) => {
                if ((parseFloat(element["Equity Percentage"].toFixed(2)) > 0) && !element.Category.toLowerCase().includes('total')) {

                    result.push({
                        id: `2.${key}`,
                        name: element.Category,
                        parent: "2",
                        value: parseFloat(element["Equity Percentage"].toFixed(2)),
                        noDrill: true
                    })
                }
            })

            assetDetail.shareholding.shareholding_promoters.map((element, key) => {
                if ((parseFloat(element["Equity Percentage"].toFixed(2)) > 0) && !element.Category.toLowerCase().includes('total')) {

                    result.push({
                        id: `1.${key}`,
                        name: element.Category,
                        parent: "1",
                        value: parseFloat(element["Equity Percentage"].toFixed(2)),
                        noDrill: true
                    })
                }
            })
            {
                setTimeout(() => {
                    let ele = document.getElementsByClassName("highcharts-credits")[0]
                    if (ele) {
                        ele.remove()
                    }
                }, 100)
            }
            return result
        } catch (error) {

        }
    }

    // asset price are chart options
    const [areaChartOptions, setAreaChartOptions] = useState({
        hAxis: {
            titleTextStyle: {
                color: '#333'
            },
            gridlines: {
                color: "transparent"
            },
            format: "dd MMM, YYYY"
        },
        vAxis: {
            minValue: 0,
            gridlines: {
                color: 'transparent'
            }
        },
        legend: {
            position: 'none'
        },
        chartArea: {
            width: '80%',
            height: '80%',

        },
        crosshair: {
            trigger: 'both',
            focused: {
                color: 'var(--line-border-empty)',
            }

        },
        pointSize: 0.5,
        tooltip: { isHtml: true }

    });

    // get price chart data by asset id
    useEffect(() => {
        (async () => {
            try {
                setShowMobBS(true)
                // setShowDefaultMobBtn(true)
                setChartData([])
                await getAssetPriceChartDataById(assetId, "MAX")
            } catch (error) {

            }
        })()
    }, [assetDetail])

    // format price chart data
    useEffect(() => {
        const controller = new AbortController();
        try {
            if (priceChartData && priceChartData.price_graph && priceChartData.price_graph.length > 0) {
                let data = [[{ 'type': 'date', 'label': 'Date' }, { 'type': 'number', 'label': 'Buy Price' }, { 'type': 'string', 'role': 'style' }, { type: 'string', role: 'tooltip', 'p': { 'html': true } }]]
                // { type: 'string', role: 'annotation' }
                priceChartData.price_graph.map((element, key) => {
                    let date = new Date(element.date)
                    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
                    let formatted_date = date.getDate() + " " + months[date.getMonth()] + ", " + date.getFullYear()
                    if (element.Particulars) {
                        data.push([date, element.buy_price, 'point { size: 5; shape-type: circle; fill-color: #a52714; }', `<p style="white-space: nowrap; margin-bottom:2px; margin-right:10px"><b>Particulars:</b> ${element.Particulars}</br><b>Ratio/Rates/Amount: </b>${element["Ratio/Rates/Amount"]}</br><b>Remarks:</b> ${element.Remarks}</p>`])
                    } else {
                        data.push([date, element.buy_price, 'point { size: 0.1;}', `<p style="white-space: nowrap;  margin-bottom:2px; margin-right:10px"><b>${formatted_date}</br>Buy:</b> ${element.buy_price}</p>`])
                    }

                })
                setChartData(data)

            } else {
                setChartData([])
            }
        } catch (error) {
            console.error(error);
        }
        return () => {
            controller.abort();
        };
    }, [priceChartData])

    window.addEventListener('resize', () => {
        updateAreaChartGradient()
    });


    // update area chart gradient
    function updateAreaChartGradient() {
        try {
            var interval = setInterval(() => {
                if (document.getElementById("areaChart")) {
                    let container = document.getElementById("areaChart")
                    let path = container.getElementsByTagName("path")[0]
                    if (path) {
                        path.setAttribute("fill", "url(#my-gradient)")
                        clearInterval(interval)
                    }
                }
            }, 10)

        } catch (error) {

        }
    }


    // calculate balance sheet
    const [col, setCol] = useState([])
    const [data, setRow] = useState([])
    const [blType, setBLType] = useState("Standalone")

    useEffect(() => {
        const controller = new AbortController();

        // prepareBLSheet()
        if (activeDetailTab.tab === configs.sharesHeaderList[4].tab) {
            setBLType("Standalone")
            prepareBLSheet("balance_sheet", "Standalone")
        } else if (activeDetailTab.tab === configs.sharesHeaderList[5].tab) {
            setBLType("Standalone")
            prepareBLSheet("profit_and_loss", "Standalone")
        } else if (activeDetailTab.tab === configs.sharesHeaderList[6].tab) {
            generateShareHoldingData("reset")
        }
        return () => {
            controller.abort();
        };
    }, [activeDetailTab])

    // balance sheet(financials logic)
    function prepareBLSheet(inputData, blType) {
        try {
            let columns = [
                {
                    title: "",
                    field: "category",
                    render: rowData => {
                        return <label className={` m-0 pl-${(rowData.tableData.path.length - 1) * 2}`}>{rowData["category"]}</label>
                    }
                }
            ]
            if (assetDetail[inputData][blType].Date && assetDetail[inputData][blType].Date.length > 0) {
                assetDetail[inputData][blType].Date.forEach(element => {
                    columns.push({
                        title: element,
                        field: element,
                        type: 'numeric'
                    })
                });

                let rows = []
                if (activeDetailTab.tab === configs.sharesHeaderList[5].tab) {
                    rows = reduceToArray(assetDetail[inputData][blType], 1, rows, columns)
                } else {
                    Object.keys(assetDetail[inputData][blType]).forEach((element, key) => {
                        if (!element.toLowerCase().includes("type") && !element.toLowerCase().includes("date")) {
                            rows = reduceToArray(assetDetail[inputData][blType][element], key + 1, rows, columns)
                        }
                    })
                }
                let arr1d = [].concat(...rows);
                setCol(columns)
                setRow(arr1d)

            } else {
                setCol([])
                setRow([])
            }
        } catch (error) {
            console.error(error)
        }
    }
    function reduceToArray(parent, parentId, rowsData, columns, nested) {
        try {
            let rows = rowsData

            Object.keys(parent).forEach((element, key) => {
                if (!element.toLowerCase().includes("type") && !element.toLowerCase().includes("date")) {
                    let mainid = rows.length + 1
                    let data = {
                        id: mainid
                    }
                    data["category"] = element
                    if (nested)
                        data["parentId"] = parentId
                    else {
                        data["isTreeExpanded"] = true
                    }
                    if (!Array.isArray(parent[element])) {
                        let keys = Object.keys(parent[element])
                        let ele = '', count = 0
                        let sum = []
                        keys.forEach((element, key) => {
                            if (element.toLowerCase().includes("total")) {
                                ele = element
                                count += 1;
                            }
                        })
                        if (count === 0) {
                            keys.forEach((elementr, key) => {
                                Object.keys(parent[element][elementr]).forEach((selement, key) => {
                                    if (selement.toLowerCase().includes("total")) {
                                        sum.push(parent[element][elementr][selement])
                                    }
                                })
                            })
                        }
                        columns.forEach((elementc, key) => {
                            if ((key > 0) && sum.length > 0) {
                                let total = 0;
                                sum.forEach((row, key2) => {
                                    total += parseFloat(row[key - 1])
                                })
                                data[elementc.title] = total.toFixed(2)
                            } else if ((key > 0) && ele) {
                                data[elementc.title] = parent[element][ele][key - 1].toFixed(2)
                            } else if (key > 0) {
                                data[elementc.title] = ''
                            }
                        })
                        rows.push(data)
                        reduceToArray(parent[element], mainid, rows, columns, true)
                    }
                    else {
                        if (!element.toLowerCase().includes("total") || !nested) {
                            let count = 0;
                            columns.forEach((elementc, key) => {
                                if ((parent[element][key - 1]) && (parent[element][key - 1]) != 0) {
                                    count++;
                                }
                                if (key > 0) {
                                    data[elementc.title] = parent[element][key - 1].toFixed(2)
                                }
                            })
                            if (count > 0)
                                rows.push(data)
                        }
                    }
                }
            })
            return rows
        } catch (error) {
            console.error(error)
        }
    }

    // function calculate cost
    function calculateCost(e) {
        try {
            const re = /^[0-9]+$/;
            let res = e.target.value.match(re);
            if (!assetSlabsDetail) {
                setError(configs.noShareMessage)
            }
            else if (res && assetSlabsDetail) {
                setError("")
                setQuantity(parseInt(e.target.value))
                for (let sri = 0; sri < assetSlabsDetail.slabs.length; sri++) {
                    if (e.target.value >= parseFloat(assetSlabsDetail.slabs[sri].min) && (sri === assetSlabsDetail.slabs.length - 1)) {
                        setRate(assetSlabsDetail.slabs[sri].price * ((100 - assetSlabsDetail.slabs[sri].discount) / 100))
                        setSlab(assetSlabsDetail.slabs[sri])
                        setCost(e.target.value * parseFloat(assetSlabsDetail.slabs[sri].price * ((100 - assetSlabsDetail.slabs[sri].discount) / 100)))
                        break;
                    }
                    else if (e.target.value >= parseFloat(assetSlabsDetail.slabs[sri].min) && e.target.value <= parseFloat(assetSlabsDetail.slabs[sri].max)) {
                        setRate(assetSlabsDetail.slabs[sri].price * ((100 - assetSlabsDetail.slabs[sri].discount) / 100))
                        setSlab(assetSlabsDetail.slabs[sri])
                        setCost(e.target.value * parseFloat(assetSlabsDetail.slabs[sri].price * ((100 - assetSlabsDetail.slabs[sri].discount) / 100)))
                        break;
                    }
                }
            }
            else {
                setError("Only numbers are allowed for quantity.")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // function to create shares order
    async function createSharesOrder() {
        try {
            if (!assetSlabsDetail) {
                setError(configs.noShareMessage)
            } else if (quantity >= assetSlabsDetail.slabs[0].min) {
                document.getElementById("bOrderBtn").classList.toggle("d-none")
                document.getElementById("bOrderBtnLoader").classList.toggle("d-none")

                let response = await createAssetBuyOrder(assetId, quantity)
                if (response.success && response.success === configs.errorMessages.success) {
                    await assetOrderDetailById(response.data.order_id)
                    setError("")
                    setQuantity("")
                    setCost("")
                    if (response.data.order_status === "generated") {
                        setShowMobBS(false)
                        setShowSuccess(true)
                        history.push('/user/assets/payment')
                    } else {
                        setVerifyOrderPopup(true)
                    }
                } else {
                    setError(response)
                }

                document.getElementById("bOrderBtn").classList.toggle("d-none")
                document.getElementById("bOrderBtnLoader").classList.toggle("d-none")
            } else {
                setError(`Minimum allowed purchase quantity is ${formatNumber(assetSlabsDetail.slabs[0].min)}.`)
            }
        } catch (error) {

        }
    }

    // function to sell shares
    async function sellShares() {
        const red = /^[0-9]\d*(\.\d{1,2})?$/;
        const re = /^[0-9]+$/;
        if (!sellQuant.match(re)) {
            setSellError("Only numbers are allowed for quantity.")
        } else if (!sellRate.match(red)) {
            setSellError("Only numbers and decimals upto 2 places are allowed for rate/share.")
        } else {
            document.getElementById("sOrderBtn").classList.toggle("d-none")
            document.getElementById("sOrderBtnLoader").classList.toggle("d-none")

            let response = await createAssetSellOrder(assetId, sellQuant, sellRate)
            if (response.success && response.success === configs.errorMessages.success) {
                setSellError("")
                // await assetOrderDetailById(orderSummary.orderId)
                setSellQuant("")
                setSellRate("")
                setVerifyOrderPopup(true)
                setShowSuccess(true)
            } else {
                setSellError(response)
            }

            document.getElementById("sOrderBtn").classList.toggle("d-none")
            document.getElementById("sOrderBtnLoader").classList.toggle("d-none")
        }
    }

    // function to generate enquiry
    async function generateEnquiry() {
        const red = /^[0-9]\d*(\.\d{1,2})?$/;
        const re = /^[0-9]+$/;
        if (!sellQuant.match(re)) {
            setSellError("Only numbers are allowed for quantity.")
        } else if (!sellRate.match(red)) {
            setSellError("Only numbers and decimals upto 2 places are allowed for rate/share.")
        } else if (!sellType) {
            setSellError("Please select valid enquiry type.")
        } else if (!sellRemark) {
            setSellError("Please enter valid remark.")
        } else {
            document.getElementById("enquiryBtn").classList.toggle("d-none")
            document.getElementById("enquiryBtnLoader").classList.toggle("d-none")

            // let response = await sharesEnquiryByCompany(assetId, sellQuant, sellRate, sellType, sellRemark)
            // if (response && response.success === configs.errorMessages.success) {
            //     setSellError("")

            //     setSellQuant("")
            //     setSellRate("")
            //     setSellRemark("")
            //     setSellType(configs.shareSub.buy)
            //     setShowLastSlab(true)
            // } else {
            //     setSellError(response)
            // }

            document.getElementById("enquiryBtn").classList.toggle("d-none")
            document.getElementById("enquiryBtnLoader").classList.toggle("d-none")
        }
    }

    const handleCompanyNameChange = (e, value) => {
        try {
            history.push(`/user/assets/company/${value.company_name}`)
        } catch (error) {

        }
    }

    // shares main page ui code
    return (
        <div className="initial-div">
            <TopBarPage />
            <div className="initial-contain">
                <LeftBarPage />
                <div id="shareContainer" className="shares-container">
                    <div id="shareHeaderContainer" className="shares-header">
                        {assetDetail ?
                            <div className="row d-flex align-items-center">
                                <div className="col-12 d-block">
                                    <div className='row mx-0'>
                                        <div className='col-md-2 p-0 pr-2'>
                                            {assetDetail.basic_data && assetDetail.basic_data.Logo ? <img src={`${process.env.REACT_APP_BASEURL}/all/companyLogo/${assetDetail.basic_data.Logo}`} className="sharesCompLogo" /> : null}
                                        </div>
                                        <div className='col-md-10 p-0'>
                                            <div className='row mx-0 align-items-center'>
                                                <div className='col-md-8 col-12 p-0'>
                                                    <p className="font-weight-bold text-primary heading4 mb-0">{assetDetail.basic_data["Legal Name (Read Only)"]}
                                                    </p>
                                                    <p className='m-0 p-0 text-custom-grey'>{assetDetail.basic_data.ISIN}
                                                    </p>
                                                </div>

                                                <div className='col-md-4 d-md-block d-none'>
                                                    <Autocomplete
                                                        className="f-12"
                                                        id="emailIP"
                                                        placeholder="E-mail"
                                                        getOptionLabel={option => option.company_name}
                                                        onChange={handleCompanyNameChange}
                                                        options={dupAssetList}
                                                        renderInput={(params) => <TextField {...params} placeholder="Search Company" className="f-12" />}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='row d-flex justify-content-between align-items-center'>
                                        <div className='col-6'>
                                            <label className=''>Listing Status: <b>{assetDetail.basic_data["Listing Status(Read Only)"]}</b><br />{assetDetail.basic_data["listed_on"]}</label>
                                        </div>

                                        <div className=' d-none d-md-block col-md-6 text-right'>
                                            DEMAT IN : {assetDetail.basic_data["CDSL(Read Only)"] == "Yes" ? <img src={cdsl} className="demat-logo" /> : null} {assetDetail.basic_data["NSDL(Read Only)"] == "Yes" ? <img src={nsdl} className="demat-logo" /> : null}
                                        </div>
                                        <div className='d-md-none col-6 text-right'>
                                            <div> <label className='m-0'> DEMAT IN : </label></div>
                                            {assetDetail.basic_data["CDSL(Read Only)"] == "Yes" ? <img src={cdsl} className="demat-logo" /> : null} {assetDetail.basic_data["NSDL(Read Only)"] == "Yes" ? <img src={nsdl} className="demat-logo" /> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        {/* {showDefaultMobBtn ?  */}
                        <div className="menu-list">
                            <ul className="style-menu">
                                {configs.sharesHeaderList.map((element, key) => {
                                    if (key != 1)
                                        return (<li key={`shareHeaderItme` + key} className="style-menu-item"><a href="#about" className={element === activeDetailTab ? "active" : null} onClick={(e) => {
                                            e.preventDefault()
                                            // if (showDefaultMobBtn) {
                                            setActiveDetailTab(element)
                                            // }
                                        }}>{element.tab}</a></li>)
                                })}
                            </ul>
                        </div>
                        {/* : null} */}
                    </div>
                    <div id="sharesDetailContainer" className="shares-detail-container">
                        {activeDetailTab.tab === configs.sharesHeaderList[0].tab ?
                            // price tab
                            <div className="shares-detail" onLoad={updateAreaChartGradient()}>
                                <div className='row align-items-center'>
                                    <div id="areaChart" className='col-md-9 col-12'>
                                        {chartData.length > 1 ? <Chart
                                            chartType="AreaChart"
                                            data={chartData}
                                            options={areaChartOptions}

                                        />
                                            : <p>No {graphDataSelector} data to display.</p>}

                                        <div className="company-chart-range-btn-div">
                                            <button className={`company-chart-range-btn ${graphDataSelector === "1W" ? "btn-success p-2 mr-2 curPoint" : "btn-primary p-2 mr-2 curPoint"}`} onClick={(e) => {
                                                e.preventDefault()
                                                setGraphDataSelector("1W")
                                                getAssetPriceChartDataById(assetId, "1W")
                                            }}>1W</button>
                                            <button className={`company-chart-range-btn ${graphDataSelector === "1M" ? "btn-success p-2 mr-2 curPoint" : "btn-primary p-2 mr-2 curPoint"}`} onClick={(e) => {
                                                e.preventDefault()
                                                setGraphDataSelector("1M")
                                                getAssetPriceChartDataById(assetId, "1M")
                                            }}>1M</button>
                                            <button className={`company-chart-range-btn ${graphDataSelector === "1Y" ? "btn-success p-2 mr-2 curPoint" : "btn-primary p-2 mr-2 curPoint"}`} onClick={(e) => {
                                                e.preventDefault()
                                                setGraphDataSelector("1Y")
                                                getAssetPriceChartDataById(assetId, "1Y")
                                            }}>1Y</button>
                                            <button className={`company-chart-range-btn ${graphDataSelector === "MAX" ? "btn-success p-2 mr-2 curPoint" : "btn-primary p-2 mr-2 curPoint"}`} onClick={(e) => {
                                                e.preventDefault()
                                                setGraphDataSelector("MAX")
                                                getAssetPriceChartDataById(assetId, "MAX")
                                            }}>MAX</button>
                                        </div>
                                        <svg style={{ width: "0", height: "0", position: "absolute" }}>
                                            <linearGradient id="my-gradient" x1="0%" y1="0%" x2="0%" y2="100%" >
                                                <stop offset="0%" stopColor="#0254f5" />
                                                <stop offset="60%" stopColor="#98add6" />
                                                <stop offset="100%" stopColor="var(--white)" />
                                            </linearGradient>
                                        </svg>
                                    </div>

                                    {assetDetail["52_week_data"] ?
                                        < div className='row col-md-3  mt-4 pr-0'>
                                            <p className='col-md-12 col-6 pr-0'><b>52 Week High - </b> {assetDetail["52_week_data"]["high_52_week"]}</p>
                                            <p className='col-md-12 col-6 pr-0'><b>52 Week Low - </b> {assetDetail["52_week_data"]["low_52_week"]}</p>
                                        </div>
                                        : null}
                                </div>

                                {priceChartData ?
                                    <div className="row mt-4">
                                        {priceChartData.data && priceChartData.data.message ? <div className='col-12  mt-3'>Data as per {priceChartData.data.message}</div> : null}
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">P/E</p>
                                                <p className="font-weight-bold">{priceChartData.data.pe ? formatNumber(parseFloat(priceChartData.data.pe)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">P/S</p>
                                                <p className="font-weight-bold">{priceChartData.data.ps ? formatNumber(parseFloat(priceChartData.data.ps)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">P/B</p>
                                                <p className="font-weight-bold">{priceChartData.data.pb ? formatNumber(parseFloat(priceChartData.data.pb)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Industry PE</p>
                                                <p className="font-weight-bold">{priceChartData.data.industry_pe ? formatNumber(parseFloat(priceChartData.data.industry_pe)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Dividend (Per Share)</p>
                                                <p className="font-weight-bold">{priceChartData.data.dividend ? formatCurrency(parseFloat(priceChartData.data.dividend)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Dividend Yield</p>
                                                <p className="font-weight-bold">{priceChartData.data.dividend_yield ? formatNumber(parseFloat(priceChartData.data.dividend_yield)) : 0}%</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Face value</p>
                                                <p className="font-weight-bold">{priceChartData.data.face_value ? formatCurrency(parseFloat(priceChartData.data.face_value)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">EPS</p>
                                                <p className="font-weight-bold">{priceChartData.data.eps ? formatCurrency(parseFloat(priceChartData.data.eps)) : 0}</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">PAT</p>
                                                <p className="font-weight-bold">{priceChartData.data.pat ? formatCurrency(parseFloat(priceChartData.data.pat)) : 0} Cr</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Share Capital</p>
                                                <p className="font-weight-bold">{priceChartData.data.equity ? formatCurrency(parseFloat(priceChartData.data.equity)) : 0} Cr</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Market Cap</p>
                                                <p className="font-weight-bold">{priceChartData.data.market_cap ? formatCurrency(parseFloat(priceChartData.data.market_cap)) : 0} Cr</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-6">
                                            <div className="info-card">
                                                <p className="">Sales</p>
                                                <p className="font-weight-bold">{priceChartData.data.sales ? formatCurrency(parseFloat(priceChartData.data.sales)) : 0} Cr</p>
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                {assetDetail.corporate_action && assetDetail.corporate_action.length > 0 ?
                                    <div className='table-scroll'>
                                        <table className="table table-bordered mt-3">
                                            <caption style={{ captionSide: 'top' }}>Corporate Action</caption>
                                            <thead>
                                                <tr>
                                                    {Object.keys(assetDetail.corporate_action[0]).map((element, key) => {
                                                        return (
                                                            <th scope="col" key={`cat` + key} style={element.toLowerCase().includes("ratio") ? { overflowWrap: "anywhere" } : !isNaN(assetDetail.corporate_action[0][element]) ? { width: "15%" } : element.toLowerCase().includes("date") ? { whiteSpace: "nowrap" } : null}>{element}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {assetDetail.corporate_action.map((element, key) => {
                                                    return (
                                                        <tr key={`ca` + key}>
                                                            {Object.keys(element).map((value, key) => {
                                                                return (
                                                                    <td key={`ca` + key} >{element[value]}</td>
                                                                )
                                                            })}

                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    : <p className='mt-5'>No Corporate Actions found</p>}

                                <p className='mt-4 m-0 p-0'>About the Company</p>
                                {assetDetail.basic_data && assetDetail.basic_data["About the Company"] ?
                                    <div id="fttSectionData">
                                        <div className='text-justify' dangerouslySetInnerHTML={{ __html: assetDetail.basic_data["About the Company"] }} />
                                    </div>
                                    : <div className="shares-detail"><p>No About Data found.</p></div>}

                                {assetDetail.comments && assetDetail.comments["About the Company"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["About the Company"]}</div> : null}

                            </div> :



                            // balance sheet div 
                            activeDetailTab.tab === configs.sharesHeaderList[4].tab ?
                                <div className="shares-detail subContent">
                                    <div className="d-flex justify align-items-center">
                                        <div className="col-6">
                                            <select value={blType} className="" onChange={(e) => {
                                                setBLType(e.target.value)
                                                prepareBLSheet("balance_sheet", e.target.value)
                                            }}>
                                                <option value="Consolidate">Consolidated</option>
                                                <option value="Standalone">Standalone</option>
                                            </select>

                                        </div>
                                        {data && data.length > 0 ? <div className="col-6 text-right ">
                                            in Rs. Cr.
                                        </div> : null}
                                    </div>
                                    {data && data.length > 0 ? <MaterialTable
                                        title=""
                                        options={{
                                            search: false,
                                            selection: false,
                                            showTitle: false,
                                            toolbar: false,
                                            paging: false,
                                            sorting: false,
                                            rowStyle: (rowData) => {
                                                return {
                                                    backgroundColor: rowData["category"].toLowerCase().includes("total") ? "var(--primary)" : (rowData.tableData.childRows || rowData.tableData.path.length === 1) ? "#f5f5f5" : "var(--white)",
                                                    color: rowData["category"].toLowerCase().includes("total") ? "var(--white)" : "#000",
                                                    fontWeight: (rowData.tableData.childRows || rowData.tableData.path.length === 1) ? "bolder" : "",
                                                    margin: 0,
                                                    paddingLeft: `${(rowData.tableData.path.length - 1) * 2}px`
                                                };
                                            }
                                        }}
                                        data={data}
                                        columns={col}
                                        parentChildData={(row, rows) =>
                                            rows.find(a => a.id === row.parentId)
                                        }

                                    /> : <p className='mt-3'>No Balance Sheet data found</p>}
                                    {assetDetail.comments && assetDetail.comments["Balance Sheet"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Balance Sheet"]}</div> : null}
                                </div> :

                                // profit and loss div
                                activeDetailTab.tab === configs.sharesHeaderList[5].tab ?
                                    <div className="shares-detail subContent">
                                        <div className="d-flex justify align-items-center">
                                            <div className="col-6">
                                                <select value={blType} className="" onChange={(e) => {
                                                    setBLType(e.target.value)
                                                    prepareBLSheet("profit_and_loss", e.target.value)
                                                }}>
                                                    <option value="Consolidate">Consolidated</option>
                                                    <option value="Standalone">Standalone</option>
                                                </select>

                                            </div>
                                            {data && data.length > 0 ? <div className="col-6 text-right ">
                                                in Rs. Cr.
                                            </div> : null}
                                        </div>
                                        {data && data.length > 0 ? <MaterialTable
                                            title=""
                                            options={{
                                                search: false,
                                                selection: false,
                                                showTitle: false,
                                                toolbar: false,
                                                paging: false,
                                                sorting: false,
                                                rowStyle: (rowData) => {
                                                    return {
                                                        backgroundColor: ["Profit Before Interest and Taxes", "Profit for the Period", "Profit Before Tax"].includes(rowData["category"]) ? "var(--primary)" : (rowData.tableData.childRows || rowData.tableData.path.length === 1) ? "var(--white)" : "#f5f5f5",
                                                        color: ["Profit Before Interest and Taxes", "Profit for the Period", "Profit Before Tax"].includes(rowData["category"]) ? "var(--white)" : "#000",
                                                        fontWeight: (rowData.tableData.childRows || rowData.tableData.path.length === 1) ? "bolder" : "",
                                                        margin: 0,
                                                        paddingLeft: `${(rowData.tableData.path.length - 1) * 2}px`
                                                    };
                                                }
                                            }}
                                            data={data}
                                            columns={col}
                                            parentChildData={(row, rows) =>
                                                rows.find(a => a.id === row.parentId)
                                            }

                                        /> : <p className='mt-3'>No Profit and Loss data found</p>}
                                        {assetDetail.comments && assetDetail.comments["Profit and Loss"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Profit and Loss"]}</div> : null}

                                    </div> :

                                    // shareholding div
                                    activeDetailTab.tab === configs.sharesHeaderList[6].tab ?
                                        <div className="shares-detail">
                                            {assetDetail.shareholding && (assetDetail.shareholding.shareholding_public && assetDetail.shareholding.shareholding_public.length > 0) || (assetDetail.shareholding.shareholding_promoters && assetDetail.shareholding.shareholding_promoters.length > 0) ? <div className="row">
                                                <div className="col-lg-6">
                                                    <div id="donutchart" className='w-100'>
                                                        <HighchartsReact highcharts={Highcharts} options={{
                                                            chart: {
                                                                height: "100%"
                                                            },
                                                            title: {
                                                                text: "Shareholdings Summary"
                                                            },

                                                            series: [
                                                                {
                                                                    type: "sunburst",
                                                                    data: generateShareHoldingData(),
                                                                    allowDrillToNode: true,
                                                                    cursor: "pointer",
                                                                    dataLabels: {
                                                                        format: "{point.name}",

                                                                        rotationMode: "circular"
                                                                    },
                                                                    levels: [
                                                                        {
                                                                            level: 1,
                                                                            // levelIsConstant: false,
                                                                            colorVariation: {
                                                                                key: "brightness",
                                                                                to: 0.1
                                                                            }

                                                                        },
                                                                        {
                                                                            level: 2,
                                                                            // colorByPoint: true,
                                                                            colorVariation: {
                                                                                key: "brightness",
                                                                                to: -0.5
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }} />
                                                    </div>

                                                </div>
                                                <div className="col-lg-6 table-scroll">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style={{ width: '70%' }}>Shareholder</th>
                                                                <th scope="col" className='text-right'>Holdings (%)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {assetDetail.shareholding.shareholding_table ? assetDetail.shareholding.shareholding_table.map((element, key) => {
                                                                return (
                                                                    <tr key={`shKy` + key}>
                                                                        <td>{element["Shareholding Above 5%"]}</td>
                                                                        <td className='text-right'>{element["Holding %"].toFixed(2)}</td>
                                                                    </tr>
                                                                )
                                                            }) : null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> : <p className='mt-3'>No Shareholding data found</p>}
                                            {assetDetail.comments && assetDetail.comments["Shareholding Summary"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Shareholding Summary"]}</div> : null}
                                            {assetDetail.comments && assetDetail.comments["Shareholding Table"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Shareholding Table"]}</div> : null}
                                            {assetDetail.comments && assetDetail.comments["Shareholding Public"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Shareholding Public"]}</div> : null}
                                            {assetDetail.comments && assetDetail.comments["Shareholding Promoters"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Shareholding Promoters"]}</div> : null}
                                        </div> :

                                        // team management div
                                        activeDetailTab.tab === configs.sharesHeaderList[7].tab ?
                                            <div className="shares-detail">
                                                {assetDetail.team_management_details && assetDetail.team_management_details.length > 0 ?
                                                    assetDetail.team_management_details.map((element, key) => {
                                                        return (
                                                            <div>
                                                                <p className='m-0 p-0'><span className='font-weight-bold' >{element.Name} - </span>{element.Designation}</p>
                                                                <p>{element.Description} {element["Share Percentage"] > 0 ? `Shareholding in the company - ${element["Share Percentage"]}%` : null}</p>
                                                            </div>
                                                        )
                                                    })
                                                    : <p className='mt-3'>No Management details found</p>}
                                                {assetDetail.comments && assetDetail.comments["Team Management Details"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Team Management Details"]}</div> : null}

                                            </div> :

                                            // ancillary div
                                            activeDetailTab.tab === configs.sharesHeaderList[8].tab ?
                                                <div className="shares-detail">
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Ratio Analysis</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.ratio_analysis && assetDetail.ratio_analysis.Date ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" key={`pl`}></th>
                                                                                {assetDetail.ratio_analysis.Date.map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`pl` + key} className='text-right'>{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Object.keys(assetDetail.ratio_analysis).map((element, key) => {
                                                                                if (element.toLowerCase() !== "date" && element.toLowerCase() !== "type") {
                                                                                    return (
                                                                                        <tr key={`plr` + key}>
                                                                                            <td key={`plt`}><b>{element}</b></td>
                                                                                            {assetDetail.ratio_analysis[element].map((value, key) => {
                                                                                                return (
                                                                                                    <td key={`plt` + key} className='text-right'>{value.toFixed(2)}</td>
                                                                                                )
                                                                                            })}
                                                                                        </tr>
                                                                                    )
                                                                                }

                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Ratio Analysis data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Ratio Analysis"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Ratio Analysis"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Peers</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.peers && assetDetail.peers.length > 0 ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                {Object.keys(assetDetail.peers[0]).map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`tm` + key} className={!isNaN(assetDetail.peers[0][element]) ? "text-right" : null}>{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {assetDetail.peers.map((element, key) => {
                                                                                return (
                                                                                    <tr key={`tmr` + key}>
                                                                                        {Object.keys(element).map((value, key) => {
                                                                                            return (
                                                                                                <td key={`tmt` + key} className={!isNaN(element[value]) ? "text-right" : null}>{element[value]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Peers data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Peers"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Peers"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Industry Benchmarking</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.industry_bench_marking && assetDetail.industry_bench_marking.Year ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" key={`pl`}></th>
                                                                                {assetDetail.industry_bench_marking.Year.map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`pl` + key} className="text-right">{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Object.keys(assetDetail.industry_bench_marking).map((element, key) => {
                                                                                if (element.toLowerCase() !== "date" && element.toLowerCase() !== "year") {
                                                                                    return (
                                                                                        <tr key={`plr` + key}>
                                                                                            <td key={`plt`}><b>{element}</b></td>
                                                                                            {assetDetail.industry_bench_marking[element].map((value, key) => {
                                                                                                return (
                                                                                                    <td key={`plt` + key} className={!isNaN(value) ? "text-right" : null}>{value}</td>
                                                                                                )
                                                                                            })}
                                                                                        </tr>
                                                                                    )
                                                                                }

                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Industry Benchmarking data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Industry Bench Marking"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Industry Bench Marking"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Segment Revenue</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.segment_revenue && assetDetail.segment_revenue.length > 0 ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                {Object.keys(assetDetail.segment_revenue[0]).map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`tm` + key} className={!isNaN(assetDetail.segment_revenue[0][element]) ? "text-right" : null}>{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {assetDetail.segment_revenue.map((element, key) => {
                                                                                return (
                                                                                    <tr key={`tmr` + key}>
                                                                                        {Object.keys(element).map((value, key) => {
                                                                                            return (
                                                                                                <td key={`tmt` + key} className={!isNaN(element[value]) ? "text-right" : null}>{element[value]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Segment Revenue data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Segment Revenue"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Segment Revenue"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Subsidaries</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.subsidaries && assetDetail.subsidaries.length > 0 ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                {Object.keys(assetDetail.subsidaries[0]).map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`tm` + key} className={!isNaN(assetDetail.subsidaries[0][element]) ? "text-right" : null}>{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {assetDetail.subsidaries.map((element, key) => {
                                                                                return (
                                                                                    <tr key={`tmr` + key}>
                                                                                        {Object.keys(element).map((value, key) => {
                                                                                            return (
                                                                                                <td key={`tmt` + key} className={!isNaN(element[value]) ? "text-right" : null}>{element[value]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Subsidaries data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Subsidaries"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Subsidaries"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Securities Allotment</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.securities_allotment && assetDetail.securities_allotment.length > 0 ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                {Object.keys(assetDetail.securities_allotment[0]).map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`tm` + key} className={!isNaN(assetDetail.securities_allotment[0][element]) ? "text-right" : null}>{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {assetDetail.securities_allotment.map((element, key) => {
                                                                                return (
                                                                                    <tr key={`tmr` + key}>
                                                                                        {Object.keys(element).map((value, key) => {
                                                                                            return (
                                                                                                <td key={`tmt` + key} className={!isNaN(element[value]) ? "text-right" : null}>{element[value]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Securities Allotment data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Securities Allotment"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Securities Allotment"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Corporate Governance</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {assetDetail.corporate_governance && assetDetail.corporate_governance.length > 0 ?
                                                                <div className='table-scroll'>
                                                                    <table className="table table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                {Object.keys(assetDetail.corporate_governance[0]).map((element, key) => {
                                                                                    return (
                                                                                        <th scope="col" key={`tm` + key} className={!isNaN(assetDetail.corporate_governance[0][element]) ? "text-right" : null}>{element}</th>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {assetDetail.corporate_governance.map((element, key) => {
                                                                                return (
                                                                                    <tr key={`tmr` + key}>
                                                                                        {Object.keys(element).map((value, key) => {
                                                                                            return (
                                                                                                <td key={`tmt` + key} className={!isNaN(element[value]) ? "text-right" : null}>{element[value]}</td>
                                                                                            )
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                : <p className='mt-3'>No Corporate Governance data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Corporate Governance"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Corporate Governance"]}</div> : null}

                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div> :

                                                // press tab
                                                activeDetailTab.tab === configs.sharesHeaderList[9].tab ?
                                                    <div className="shares-detail">
                                                        <div className='row'>
                                                            {assetDetail[activeDetailTab.data] && assetDetail[activeDetailTab.data].length > 0 ?
                                                                assetDetail[activeDetailTab.data].map((element, key) => {
                                                                    return (<div className="press-card col-lg-5 border-lg-right border " key={`pressCard` + key} >
                                                                        {testImage(element.news_image) ? <img className="card-img-top" src={element.news_image} alt="Card image cap" /> : null}
                                                                        <div className="card-body" style={{ overflow: "auto" }}>
                                                                            <span className="text-custom-grey font-weight-normal"><i className="far fa-clock mr-2 "></i>{formatPressDate(element.news_date)}</span> <br />
                                                                            <p className="card-title subheading2 font-weight-bold m-0 p-0 mt-1">{element.news_heading}</p>
                                                                            <span><a href={element.news_link} target="_blank" className="text-custom-grey font-weight-normal  custom-link">{element.news_website}</a></span> <br />
                                                                            <p className="card-text mt-2 ">{element.news_description}</p>
                                                                            <div className="text-center">
                                                                                <a href={element.news_link} target="_blank" className="custom-link"><i className="fab fa-readme mr-2"></i>Read full article</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>)
                                                                }) : <p className='mt-3'>No Press data found</p>}
                                                            {assetDetail.comments && assetDetail.comments["Press"] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments["Press"]}</div> : null}
                                                        </div>

                                                    </div> :


                                                    // company information
                                                    activeDetailTab.tab === configs.sharesHeaderList[11].tab ?
                                                        <div className="shares-detail">
                                                            {assetDetail.basic_data ?
                                                                <p className='col-12'>
                                                                    <b>ISIN : </b>{assetDetail.basic_data.ISIN}<br />
                                                                    <b>PAN : </b>{assetDetail.basic_data["PAN"]}<br />
                                                                    <b>Address : </b>{assetDetail.basic_data.Address}<br />
                                                                    <b>Email : </b>{assetDetail.basic_data.Email}<br />
                                                                    <b>Phone Number : </b>{assetDetail.basic_data.Phone}<br />
                                                                    <b>Date of Incorporation : </b>{assetDetail.basic_data["Date of Incorporation"]}<br />
                                                                    <b>Date of Last AGM : </b>{assetDetail.basic_data["Date of Last AGM"]}<br />
                                                                    <b>Data as Date : </b>{assetDetail.basic_data["Data as Date"]}<br />
                                                                    <b>Source of Data : </b>{assetDetail.basic_data["Source of Data"]}<br />
                                                                </p>
                                                                : <p className='mt-3'>No Company Information found</p>}
                                                        </div> :

                                                        // default div
                                                        <div id="fttSectionData" className="shares-detail">
                                                            {assetDetail[activeDetailTab.data] ? <div dangerouslySetInnerHTML={{ __html: assetDetail[activeDetailTab.data] }} /> : <p>No {activeDetailTab.tab} Data found.</p>}

                                                            {assetDetail.comments && assetDetail.comments[activeDetailTab.data] ? <div className="alert alert-warning mt-4" role="alert">{assetDetail.comments[activeDetailTab.data]}</div> : null}
                                                        </div>
                        }
                        {assetDetail.basic_data && assetDetail.basic_data.disabletrading === 1 ?
                            <div className="shares-buy">
                                <div className='d-flex justify-content-center'>
                                    <p className='subheading2 m-0 p-0 font-weight-bold'>Enquiry Form</p>
                                </div>
                                <form action="">

                                    <div className="form-row m-0 p-0 break-all-word mt-3">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="share_qty" className="text-custom-grey">Quantity</label>
                                                <input type="text" className="form-control defaultFont" pattern="[0-9]" inputMode="numeric" id="share_qty" value={sellQuant} onChange={(e) => {
                                                    setSellQuant(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="share_qty" className="text-custom-grey">Rate/Share</label>
                                                <input type="text" className="form-control defaultFont" pattern="[0-9]" inputMode="numeric" id="share_rate" value={sellRate} onChange={(e) => {
                                                    setSellRate(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="share_qty" className="text-custom-grey">Enquiry Type</label>
                                                <select className="form-control defaultFont text-capitalize" id="share_type" value={sellType} onChange={(e) => {
                                                    setSellType(e.target.value)
                                                }} >
                                                    <option value={configs.shareSub.buy} className="text-capitalize">{configs.shareSub.buy}</option>
                                                    <option value={configs.shareSub.sell} className="text-capitalize">{configs.shareSub.sell}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="share_qty" className="text-custom-grey">Remarks</label>
                                                <textarea className="form-control defaultFont" id="share_remark" value={sellRemark} onChange={(e) => {
                                                    setSellRemark(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-check p-0">
                                            <p className="text-danger" id="calcShareError">{sellError}</p>
                                        </div>
                                        <div className="col-12 text-center d-flex justify-content-center">
                                            <button id="enquiryBtn" type="submit" className="btn btn-primary px-5" onClick={(e) => {
                                                e.preventDefault()
                                                generateEnquiry()
                                            }}>Submit Enquiry</button>
                                            <div id="enquiryBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            :
                            <div className="shares-buy">
                                <div className="d-flex justify">
                                    <div className="col-12 mt-2">
                                        <nav className="d-block ">
                                            <div className="nav nav-tabs nav-fill btn-buysell-nav " id="nav-tab" role="tablist">
                                                <a className={`nav-item nav-link p-0 m-0 ${shareSub === configs.shareSub.buy ? "active" : ""}`} id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true" onClick={(e) => {
                                                    e.preventDefault()
                                                    setShareSub(configs.shareSub.buy)
                                                    setOrderType(configs.shareSub.buy)
                                                }}>
                                                    <div className="btn-buy d-flex py-1 align-items-center justify-content-center h-100" >
                                                        Buy
                                                    </div>
                                                </a>
                                                <a className={`nav-item nav-link p-0 m-0 ${shareSub === configs.shareSub.sell ? "active" : ""}`} id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={(e) => {
                                                    e.preventDefault()
                                                    setShareSub(configs.shareSub.sell)
                                                    setOrderType(configs.shareSub.sell)
                                                }}>
                                                    <div className="btn-sell d-flex py-1 align-items-center justify-content-center h-100" >
                                                        Sell
                                                    </div>
                                                </a>
                                            </div>
                                        </nav>

                                    </div>
                                </div>

                                {/* ----------------------------------------buy------------------------------------ */}
                                {shareSub === configs.shareSub.buy ?
                                    <div id="buy">
                                        <div className="row mt-2 m-0 p-0 break-all-word">
                                            <div className="col-6 p-1 text-custom-grey">
                                                Quantity
                                            </div>
                                            <div className="col-6 p-1 text-right text-custom-grey">
                                                Price/share
                                            </div>
                                        </div>
                                        {assetSlabsDetail ? assetSlabsDetail.slabs.map((element, key) => {
                                            if (key !== (assetSlabsDetail.slabs.length - 1)) {
                                                return (<div key={`shareRange` + key} className="buy-card">
                                                    <p className="m-0 p-0">{formatNumber(element.min)} - {formatNumber(element.max)}</p>
                                                    {(parseFloat(element.discount) > 0) ?
                                                        <p className="text-primary m-0 p-0 font-weight-normal"><del className='text-dark f-12'>{formatCurrency(parseFloat(element.price))}</del> {formatCurrency(parseFloat(element.price * ((100 - element.discount) / 100)))}</p>
                                                        : <p className="text-primary m-0 p-0 font-weight-normal">{formatCurrency(parseFloat(element.price))}</p>
                                                    }
                                                </div>)
                                            } else {
                                                return (<div key={`shareRange` + key} className="buy-card">
                                                    <p className="m-0 p-0">&gt; {formatNumber(element.min)}</p>
                                                    {(parseFloat(element.discount) > 0) ?
                                                        <p className="text-primary m-0 p-0 font-weight-normal"><del className='text-dark f-12'>{formatCurrency(parseFloat(element.price))}</del> {formatCurrency(parseFloat(element.price * ((100 - element.discount) / 100)))}</p>
                                                        : <p className="text-primary m-0 p-0 font-weight-normal">{formatCurrency(parseFloat(element.price))}</p>
                                                    }
                                                </div>)
                                            }
                                        }) : null}
                                        <form action="" className='mt-3'>
                                            <div className="form-row m-0 p-0 break-all-word">
                                                <div className="col-8">
                                                    <div className="form-group m-0">
                                                        <label htmlFor="share_qty" className="text-custom-grey">Enter Quantity</label>
                                                        <input type="text" className="form-control subContent" value={quantity} pattern="[0-9]" inputMode="numeric" id="share_qty" onKeyUp={calculateCost} onChange={(e) => {
                                                            setQuantity(e.target.value)
                                                            setError("")
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group text-right m-0">
                                                        <label htmlFor="share_qty" className="text-custom-grey">Rate/share</label>
                                                        <input type="text" className="form-control text-center subContent" defaultValue={rate ? formatCurrency(parseFloat(rate)) : null} id="share_qty" placeholder={assetSlabsDetail.slabs ? formatCurrency(parseFloat((parseFloat(assetSlabsDetail.slabs[0].discount) > 0) && configs.userType !== configs.userTypes[0] ? assetSlabsDetail.slabs[0].price * ((100 - assetSlabsDetail.slabs[0].discount) / 100) : assetSlabsDetail.slabs[0].price)) : null} disabled />
                                                    </div>
                                                </div>
                                                <div className="col-12 text-center mb-2">
                                                    <p className="m-0 p-0"><span className="text-custom-grey">Estimated Cost : </span>{formatCurrency(cost)}</p>
                                                </div>
                                                <div className="form-check p-0">
                                                    <p className="text-danger" id="calcShareError">{error}</p>
                                                </div>
                                                <div className="col-12 text-center d-flex justify-content-center">
                                                    <button id="bOrderBtn" type="submit" className="btn btn-primary px-5" onClick={(e) => {
                                                        e.preventDefault()
                                                        createSharesOrder()
                                                    }}>Place Order</button>
                                                    <div id="bOrderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    :
                                    /* ----------------------------------------sell------------------------------------ */
                                    <div id="sell" className='mt-3'>
                                        <form action="">
                                            <div className="col-12 m-0 p-0">
                                                <div className="form-group">
                                                    <label htmlFor="share_qty" className="text-custom-grey">Tentative Rate: <span className="text-dark">{assetSlabsDetail ? formatCurrency(parseFloat(assetSlabsDetail.sell_display_price)) : formatCurrency(parseFloat(0))}</span></label>
                                                </div>
                                            </div>
                                            <div className="form-row m-0 p-0 break-all-word mt-3">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="share_qty" className="text-custom-grey">Enter Quantity</label>
                                                        <input type="text" className="form-control defaultFont" pattern="[0-9]" inputMode="numeric" id="share_qty" value={sellQuant} onChange={(e) => {
                                                            setSellQuant(e.target.value)
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="share_qty" className="text-custom-grey">Enter Rate/Share</label>
                                                        <input type="text" className="form-control defaultFont" pattern="[0-9]" inputMode="numeric" id="share_rate" value={sellRate} onChange={(e) => {
                                                            setSellRate(e.target.value)
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="form-check p-0">
                                                    <p className="text-danger" id="calcShareError">{sellError}</p>
                                                </div>
                                                <div className="col-12 text-center d-flex justify-content-center">
                                                    <button id="sOrderBtn" type="submit" className="btn btn-primary px-5" onClick={(e) => {
                                                        e.preventDefault()
                                                        sellShares()
                                                    }}>Place Order</button>
                                                    <div id="sOrderBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                                {/* ---------------------------last slab place order message--------------------- */}
                            </div>
                        }
                    </div>
                </div>
                <PlaceHolderWrapper />
                <ToastPage />
                <AUVerifyOrderPopupPage />
                {/* <div id='sharesContainer' className="shares-container"> */}
                {/* <SharesHeaderPage />
                    <SharesDetailPage /> */}
                {/* </div> */}
            </div>
            <BottomBarPage />
        </div>
    )
}