import React from 'react';
import { createContext, useContext, useState } from 'react';
import { assistedUserToken, configs } from '../../../../assets/Config';
import { assetBuyOrder, getAssetOrderDetailById, getAllAssetByClass, getAssetByClassAndId, getAssetPriceDataById, getAssetSlabById, assetManualPaymentByOrderId, assetSellOrder, assetShareTransferConfirmation, assetPaymentLinkByOrderId, cancelBuyOrderById } from '../../../apis/assistedUser/assets/AUAssetMgmtApi';
import { useLogin } from '../../CustomerLoginContext';

// create global KYC context
export const AUAssetMgmtContext = createContext();

export function AUAssetMgmtProvider({ children }) {

    const { token, logout, setLoginError, proxyEmail } = useLogin();
    const [assetClass, setAssetClass] = useState("")
    const [assetId, setAssetId] = useState("")
    const [assetList, setAssetList] = useState("")
    const [assetDetail, setAssetDetail] = useState("")
    const [assetSlabsDetail, setAssetSlabsDetail] = useState("")
    const [priceChartData, setPriceChartData] = useState("")
    const [orderType, setOrderType] = useState("buy")
    const [shareSub, setShareSub] = useState(configs.shareSub.buy)
    const [dupAssetList, setDupAssetList] = useState("")
    const [verifyOrderPopup, setVerifyOrderPopup] = useState(false)
    const [assetOrderDetail, setAssetOrderDetail] = useState("")
    const [payKYCDetail, setPayKYCDetail] = useState("")

    // get all asset by asset class
    async function getAssetsListByClass(aclass) {

        try {
            if (aclass) {
                setAssetList("")
                let response = await getAllAssetByClass(assistedUserToken(token, proxyEmail), aclass)
                if (response && response.status === 200) {
                    setAssetList(response.data)
                    setDupAssetList(response.data)
                    return { success: configs.errorMessages.success }
                } else if (response && response.status === 401) {
                    logout();
                    //setLoginError(configs.unauthorizedErrorMessage);
                }
            } else {
                setAssetList("")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // get asset by asset class and id
    async function getAssetDetailById(assetId, aclass) {
        try {
            if (assetId) {
                let response = await getAssetByClassAndId(assistedUserToken(token, proxyEmail), aclass, assetId)
                if (response && response.status === 200) {
                    setAssetDetail(response.data)
                    return { success: configs.errorMessages.success }
                } else if (response && response.status === 401) {
                    logout();
                    //setLoginError(configs.unauthorizedErrorMessage);
                }
            } else {
                setAssetDetail("")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // get asset slabs by asset id
    async function getAssetSlabDetailById(assetId) {

        try {
            if (assetId) {
                let response = await getAssetSlabById(assistedUserToken(token, proxyEmail), assetId)
                if (response && response.status === 200) {
                    setAssetSlabsDetail(response.data)
                    return { success: configs.errorMessages.success }
                } else if (response && response.status === 401) {
                    logout();
                    //setLoginError(configs.unauthorizedErrorMessage);
                }
            } else {
                setAssetDetail("")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // get asset price chart data by asset id
    async function getAssetPriceChartDataById(assetId, type) {
        try {
            let response = await getAssetPriceDataById(assistedUserToken(token, proxyEmail), 'company', assetId, type);
            if (response && response.status === 200) {
                setPriceChartData(response.data)
                return { success: configs.errorMessages.success }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // create buy asset order
    async function createAssetBuyOrder(assetId, quantity) {
        try {
            let response = await assetBuyOrder(assistedUserToken(token, proxyEmail), assetId, quantity);
            if (response && response.status === 200) {
                setAssetOrderDetail(response.data)
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // create sell asset order
    async function createAssetSellOrder(assetId, quantity, price) {
        try {
            let response = await assetSellOrder(assistedUserToken(token, proxyEmail), assetId, quantity, price);
            if (response && response.status === 200) {
                setAssetOrderDetail(response.data)
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // asset order detail
    async function assetOrderDetailById(orderId, email) {
        try {
            let response = await getAssetOrderDetailById(assistedUserToken(token, email ? email : proxyEmail), orderId);
            if (response && response.status === 200) {
                setAssetOrderDetail(response.data)
                return { success: configs.errorMessages.success }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // asset generate payment link by order id
    async function assetGeneratePaymentLinkById(orderId) {
        try {
            let response = await assetPaymentLinkByOrderId(assistedUserToken(token, proxyEmail), orderId);
            if (response && response.status === 200) {
                return {
                    success: configs.errorMessages.success,
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // asset order payment by id
    async function assetManualPaymentById(orderId, utr) {
        try {
            let response = await assetManualPaymentByOrderId(assistedUserToken(token, proxyEmail), orderId, utr);
            if (response && response.status === 200) {
                return { success: configs.errorMessages.success }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // transfer asset share by order id
    async function assetShareTransferById(orderId, consent) {
        try {
            let response = await assetShareTransferConfirmation(assistedUserToken(token, proxyEmail), orderId, consent);
            if (response && response.status === 200) {
                return { success: configs.errorMessages.success }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // cancel order by id
    async function cancelBuyOrder(orderId) {
        try {
            let response = await cancelBuyOrderById(assistedUserToken(token, proxyEmail), orderId);
            if (response && response.status === 200) {
                return { success: configs.errorMessages.success }
            } else if (response && response.status === 401) {
                logout()
                //setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // export global context
    return (
        <AUAssetMgmtContext.Provider
            value={{
                assetList,
                setAssetList,
                dupAssetList,
                setDupAssetList,
                assetDetail,
                assetId,
                assetClass,
                setAssetClass,
                setAssetId,
                getAssetsListByClass,
                getAssetDetailById,
                getAssetSlabDetailById,
                assetSlabsDetail,
                setAssetSlabsDetail,
                getAssetPriceChartDataById,
                priceChartData,
                setPriceChartData,
                orderType,
                setOrderType,
                shareSub,
                setShareSub,
                createAssetBuyOrder,
                verifyOrderPopup,
                setVerifyOrderPopup,
                assetOrderDetailById,
                assetOrderDetail,
                setAssetOrderDetail,
                assetGeneratePaymentLinkById,
                assetManualPaymentById,
                createAssetSellOrder,
                assetShareTransferById,
                payKYCDetail,
                setPayKYCDetail,
                cancelBuyOrder
            }}
        >
            {children}
        </AUAssetMgmtContext.Provider>
    );
}

export function useAUAssetMgmtContext() {
    return useContext(AUAssetMgmtContext)
}